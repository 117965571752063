.Home {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}
.StartScreen {
    opacity: 0;
    animation: fadeIn 1s ease forwards;
}
.Greetings {
    text-align: center;
    line-height: 1.6;
    opacity: 0;
    animation: fadeIn 1s ease forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
