.BlankCard {
    padding: 16px;
    margin-top: 16px;
    border: 1px solid rgba(#000, 0.2);
    border-radius: 16px;
    box-shadow: 2px 2px 2px rgba(#000, 0.1);
    cursor: pointer;
}
.ButtonGroup {
    justify-content: end;
}

.ButtonMore {
    display: block;
    background-color: orange;
    color: #fff;
    padding: 6px 12px;
    border-radius: 8px;
    & i {
        pointer-events: none;
    }
    &:hover {
        color: #fff;
    }
}
.ButtonRemove {
    background-color: tomato;
    color: #fff;
}
